import { ReloadIcon } from "@radix-ui/react-icons";
import { Button } from "@ui/components/ui/button";
import { cn } from "@ui/lib/utils";
import { ReactNode } from "react";

interface ButtonLoadingProps {
  isLoading: boolean;
  isDisabled?: boolean;
  buttonText?: string;
  buttonLoadingText?: string;
  buttonIcon?: ReactNode;
  size?: "default" | "sm" | "md" | "lg" | "xl" | "icon" | null;
  variant?:
    | "default"
    | "destructive"
    | "outline"
    | "outlinePrimary"
    | "secondary"
    | "ghost"
    | "link"
    | "linkDestructive"
    | null;
    className?: string;
}

export function ButtonLoading({
  isLoading,
  buttonLoadingText,
  buttonText,
  isDisabled = false,
  buttonIcon = null,
  className,
  ...props
}: Partial<ButtonLoadingProps>) {
  const buttonElement = isLoading ? (
    buttonIcon ? (
      <></>
    ) : (
      buttonLoadingText
    )
  ) : (
    (buttonIcon ?? buttonText)
  );
  return (
    <Button className={cn(className)} type="submit" disabled={isDisabled || isLoading} {...props}>
      {isLoading && <ReloadIcon className="mr-2 h-4 w-4 animate-spin" />}
      {buttonElement}
    </Button>
  );
}
