import { type ClassValue, clsx } from "clsx";
import { twMerge } from "tailwind-merge";

export function cn(...inputs: ClassValue[]) {
  return twMerge(clsx(inputs));
}

export const categoryAbbreviations = {
  bw: "Balance & Wellbeing",
  bp: "Benefits & Perks",
  cp: "Career Progression",
  cc: "Company Culture",
  di: "Diversity & Inclusion",
  it: "Innovation & Technology",
  js: "Job Satisfaction",
  ld: "Learning & Development",
  mo: "Management & Organization",
  mp: "Mission & Purpose",
  r: "Remuneration",
  tp: "Team & People",
};

export const categoryMap: { [key: string]: string } = categoryAbbreviations;

/**
 * This function is used for converting string to capitalized form
 * @param str
 * @returns
 */
const capitalize = (str: string): string => {
  return str.charAt(0).toUpperCase() + str.slice(1);
};

export function getCategoryLabel(category: string) {
  if (category.length > 2) {
    return capitalize(category);
  }
  return categoryMap[category];
}

export function formatToCode(attribute: string): string {
  return attribute
    .trim() // Remove extra spaces from start and end
    .toLowerCase() // Convert to lowercase
    .replace(/\s*&\s*/g, "_&_") // Convert " & " to "_&_", accounting for possible spaces or newline characters
    .replace(/[\s\n]+/g, "_"); // Convert spaces or newline characters to underscores
}

export function codeToFormat(code: string = ""): string {
  return code
    .replace(/_/g, " ") // Convert underscores to spaces
    .replace(/ _&_ /g, " & ") // Convert "_&_" to " & "
    .replace(/\b\w/g, (char) => char.toUpperCase()) // Capitalize the first letter of each word
    .trim(); // Remove extra spaces from start and end if any
}
