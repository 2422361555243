import { toast } from "@ui/components/ui/use-toast";

type ToastProps = {
  success: boolean;
  message: string;
};
export function showToast({ success, message }: ToastProps) {
  const variant = !success ? "destructive" : "default";
  const title = !success ? "Uh oh! Something went wrong." : "Success";
  toast({
    duration: 4000,
    variant,
    title,
    description: message,
  });
}
